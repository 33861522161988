import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Text,
  Input,
  Stack,
  useToast,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useSWR from "swr";
import axios from "axios";
import { useForm, SubmitHandler } from "react-hook-form";
import { BiX } from "react-icons/bi";
import useAuth from "../../hooks/useAuth";
import { getUserById } from "../../api/api";
import { SignUpForm } from "../../models/SignUpForm";
import { User } from "../../components/Types";
import Loader from "../../components/Loader";

export default function Profile() {
  const { loggedInUser } = useAuth();

  const { data: userDetailsData, error: apiError } = useSWR(
    loggedInUser?.userId && loggedInUser.userId,
    getUserById,
    {
      suspense: false,
      revalidateIfStale: true,
      refreshInterval: 4000,
    },
  );

  const [userDetails, setUserDetails] = useState<User>(userDetailsData?.data);
  const [isMobile] = useMediaQuery("(max-width: 570px)");

  useEffect(() => {
    if (userDetailsData && userDetailsData.data) {
      setUserDetails(userDetailsData);
    }
  }, [userDetailsData, apiError]);

  const [loading, setLoading] = useState(false);
  const [studentIdPic, setStudentIdPic] = useState("");
  const [studentIdPhotoFileName, setStudentIdPhotoFileName] = useState("");
  const [bankStatementPDF, setBankStatementPDF] = useState("");
  const [bankStatementFileName, setBankStatementFileName] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpForm>();

  const toast = useToast();

  const onSubmit: SubmitHandler<SignUpForm> = (data, e) => {
    e?.preventDefault();
    const formData = new FormData();
    const accessToken = localStorage.getItem("authToken");
    if (studentIdPic && bankStatementPDF) {
      formData.append("studentIdPic", studentIdPic);
      formData.append("bankStatementPDF", bankStatementPDF);
    }
    const updateUser = async () => {
      setLoading(true);
      await axios
        .put(
          `/user/${loggedInUser?.userId}`,
          {
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            studentId: data.studentId,
            whatsAppNumber: data.whatsAppNumber,

            // if studentIdPic && or bankStatementPDF is not empty, append it to the form data
            ...(studentIdPic && { studentIdPhoto: studentIdPic }),
            ...(bankStatementPDF && { bankStatement: bankStatementPDF }),
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `bearerAuth ${accessToken}`,
            },
          },
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            toast({
              position: "bottom-right",
              title: "Profile updated successfully!",
              description: "For some changes to reflect, please re-login.",
              status: "success",
              duration: 4000,
              isClosable: true,
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          const err = error.response.data.error;
          toast({
            position: "top",
            title: "Account update failed, please try again",
            description: `${err}`,
            status: "error",
            duration: 3500,
            isClosable: true,
          });
          setLoading(false);
        });
    };
    updateUser();
  };

  return (
    <Box
      w="full"
      pl={{ base: "0.8em", lg: "16.6em" }}
      pr={{ base: "0.8em", lg: "8.6em" }}
      py={10}
      minH="100vh"
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        justify="center"
        align="center"
        minH="100vh"
        h="100%"
        mt="0px"
      >
        <Flex
          direction="column"
          justify="center"
          minH="100vh"
          w="full"
          pt="3em"
        >
          <Heading
            color="#737679"
            fontWeight="semibold"
            fontSize={{ base: "2.5em" }}
            p={6}
          >
            Edit Profile
          </Heading>
          {!userDetails && !apiError && <Loader />}
          {userDetails && (
            <Box px="6" py="1em">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack
                  direction={{ base: "column", lg: "row" }}
                  mb="3"
                  spacing="1.475em"
                >
                  <FormControl>
                    <FormLabel htmlFor="firstname">First Name</FormLabel>
                    <Input
                      id="firstName"
                      type="text"
                      placeholder="John"
                      defaultValue={userDetails.data.firstname}
                      {...register("firstname", {
                        required: "First name is required!",
                        pattern: {
                          value: /^(?!New$)[^\s].*[^\s]$/,
                          message:
                            "Invalid first name. Please enter a valid name without leading or trailing spaces!",
                        },
                      })}
                    />
                    {errors.firstname && (
                      <FormHelperText color="red.400">
                        {errors.firstname.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="lastname">Last Name</FormLabel>
                    <Input
                      id="lastName"
                      type="text"
                      placeholder="Doe"
                      defaultValue={userDetails.data.lastname}
                      {...register("lastname", {
                        required: "Last name is required!",
                        pattern: {
                          value: /^(?!User$)[^\s].*[^\s]$/,
                          message:
                            "Invalid last name. Please enter a valid name without leading or trailing spaces!",
                        },
                      })}
                    />
                    {errors.lastname && (
                      <FormHelperText color="red.400">
                        {errors.lastname.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
                <FormControl mb="3">
                  <FormLabel htmlFor="studentId">Student ID</FormLabel>
                  <Input
                    id="studentId"
                    type="text"
                    placeholder="0009008"
                    defaultValue={userDetails.data.studentId}
                    {...register("studentId", {
                      required: "Student ID is required!",
                      pattern: {
                        value:
                          /^(?!.*?(\d)\1{4,})[^\s]*(?!.*?0{4,})\d{7,10}[^\s]*$/,
                        message:
                          "Invalid student ID number. Please enter a valid student ID number without spaces and with no more than four consecutive repeated digits!",
                      },
                    })}
                  />
                  {errors.studentId && (
                    <FormHelperText color="red.400">
                      {errors.studentId.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl mb="3">
                  <FormLabel htmlFor="whatsAppNumber">
                    WhatsApp Number
                  </FormLabel>
                  <Input
                    id="whatsAppNumber"
                    type="text"
                    placeholder="+26767538735"
                    defaultValue={userDetails.data.whatsAppNumber}
                    {...register("whatsAppNumber", {
                      required: "Phone number is required!",
                      pattern: {
                        value:
                          /^(?!\+26700000000$)\+(267)(?:[0-9]){6,14}(?:[0-9])$/,
                        message:
                          "Invalid phone number. Only Botswana phone numbers can register with no whitespaces!",
                      },
                    })}
                  />
                  {errors.whatsAppNumber && (
                    <FormHelperText color="red.400">
                      {errors.whatsAppNumber.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl mb="3">
                  <FormLabel htmlFor="email">School Email</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    placeholder="example@xyzuniversity.com"
                    defaultValue={userDetails.data.email}
                    {...register("email", {
                      required: "Email address is required!",
                      pattern: {
                        value: /^[^\s]+$/,
                        message:
                          "Invalid email address. Please enter a valid email address without spaces!",
                      },
                    })}
                  />
                  {errors.email && (
                    <FormHelperText color="red.400">
                      {errors.email.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl my={8}>
                  <FormLabel htmlFor="studentIdPhoto">
                    <Stack direction={{ base: "column", md: "column" }}>
                      <Text>Upload Your StudentID </Text>
                      <Text color="#737679">
                        Your student ID should clearly show your names, photo
                        and expiration date, PNG, JPG and SVG are allowed.
                      </Text>
                    </Stack>
                  </FormLabel>
                  <Stack
                    border="1px solid #ccd"
                    w="full"
                    h={{ base: "fit-content", md: "2.6em" }}
                    rounded="md"
                    direction="row"
                  >
                    <Button
                      size={{ base: "lg", md: "md" }}
                      bg="green.400"
                      color="white"
                      alignSelf="flex-start"
                      borderTopRightRadius="none"
                      borderBottomRightRadius="none"
                    >
                      Browse file
                    </Button>
                    <Input
                      id="studentIdPhoto"
                      type="file"
                      height="100%"
                      width="100%"
                      position="absolute"
                      top="0"
                      left="0"
                      opacity="0"
                      aria-hidden="true"
                      accept="image/*"
                      /* eslint-disable  @typescript-eslint/no-explicit-any */
                      onChange={(event: any) => {
                        setStudentIdPhotoFileName(event.target.files[0].name);
                        return setStudentIdPic(event.target.files[0]);
                      }}
                    />
                    {!isMobile && (
                      <Text color="#ccc" pt={1}>
                        {studentIdPic.length !== 0 ? (
                          <Flex>
                            <Text
                              color="#000"
                              _hover={{
                                color: "#000",
                                cursor: "pointer",
                              }}
                            >
                              {studentIdPhotoFileName}
                            </Text>
                            <Box zIndex="popover">
                              <BiX
                                color="red"
                                size="1.8em"
                                onClick={() => {
                                  setStudentIdPic("");
                                  setStudentIdPhotoFileName("");
                                }}
                              />
                            </Box>
                          </Flex>
                        ) : (
                          <Text color="#737679">
                            {!isMobile &&
                              "Drag and drop or browse to choose your file."}
                          </Text>
                        )}
                      </Text>
                    )}
                  </Stack>
                  {errors.studentIdPhoto && (
                    <FormHelperText color="red.400">
                      {errors.studentIdPhoto.message}
                    </FormHelperText>
                  )}
                </FormControl>
                {isMobile && (
                  <Text color="#ccc">
                    {studentIdPic.length !== 0 && (
                      <Text>{studentIdPhotoFileName}</Text>
                    )}
                  </Text>
                )}
                <FormControl my={8}>
                  <FormLabel htmlFor="bankStatement">
                    <Heading fontSize="md" color="#9DB2BF">
                      Please upload a valid FNBB statement for your student
                      account
                    </Heading>
                    <Text mb="3" color="#9DB2BF">
                      Your FNBB statement should show at least 3 months of
                      activity
                    </Text>
                  </FormLabel>
                  <Stack
                    border="1px solid #ccd"
                    w="full"
                    h={{ base: "fit-content", md: "2.6em" }}
                    rounded="md"
                    direction="row"
                  >
                    <Button
                      size={{ base: "lg", md: "md" }}
                      bg="green.400"
                      color="white"
                      alignSelf="flex-start"
                      borderTopRightRadius="none"
                      borderBottomRightRadius="none"
                    >
                      Browse file
                    </Button>
                    <Input
                      id="bankStatement"
                      type="file"
                      height="100%"
                      width="100%"
                      position="absolute"
                      top="0"
                      left="0"
                      opacity="0"
                      aria-hidden="true"
                      accept="application/pdf"
                      /* eslint-disable  @typescript-eslint/no-explicit-any */
                      onChange={(event: any) => {
                        setBankStatementFileName(event.target.files[0].name);
                        return setBankStatementPDF(event.target.files[0]);
                      }}
                    />
                    {!isMobile && (
                      <Text color="#ccc" pt={1}>
                        {bankStatementPDF.length !== 0 ? (
                          <Flex>
                            <Text
                              color="#000"
                              _hover={{
                                color: "#000",
                                cursor: "pointer",
                              }}
                            >
                              {bankStatementFileName}
                            </Text>
                            <Box zIndex="popover">
                              <BiX
                                color="red"
                                size="1.8em"
                                onClick={() => {
                                  setBankStatementPDF("");
                                  setBankStatementFileName("");
                                }}
                              />
                            </Box>
                          </Flex>
                        ) : (
                          <Text color="#737679">
                            {!isMobile &&
                              "Drag and drop or browse to choose your file."}
                          </Text>
                        )}
                      </Text>
                    )}
                  </Stack>
                  {errors.bankStatement && (
                    <FormHelperText color="red.400">
                      {errors.bankStatement.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl my="4">
                  <Button
                    type="submit"
                    color="white"
                    bgColor="green.400"
                    w="full"
                    _hover={{
                      bgGradient:
                        "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                      color: "white",
                    }}
                    isLoading={loading}
                    loadingText="Submitting"
                  >
                    Save Changes
                  </Button>
                </FormControl>
              </form>
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
